import React, { useEffect } from "react"
import { Address, testAddressIsNotExcluded } from "../addressValidator"
import { useScript } from "../hooks"

import { pickBy } from "lodash-es"
import Helmet from "react-helmet"
import Copy from "@svg-icons/fa-solid/copy.svg"
import CheckCircle from "@svg-icons/fa-regular/check-circle.svg"
import TimesCircle from "@svg-icons/fa-regular/times-circle.svg"

// TODO: make a way so he can copy and paste a bunch at once.

declare global {
  interface Window {
    pca: any
  }
}

const fields: Partial<Record<keyof Address, string>> = {
  SubBuilding: "Apartment Number",
  BuildingName: "Building Name",
  BuildingNumber: "Building Number",
  Street: "Street",
  City: "City",
  PostalCode: "Postal Code",
  Province: "Province",
  CountryIso2: "Country",
}

const AddressTool = () => {
  const [selectedFields, setSelectedFields] = React.useState<
    Partial<Record<keyof Address, boolean>>
  >({
    City: true,
    PostalCode: true,
    Province: true,
    CountryIso2: true,
    Street: true,
  })

  const [address, setAddress] = React.useState<Address | null>(null)
  const [validationAddress, setvalidationAddress] =
    React.useState<Address | null>(null)

  const exclusion = React.useRef<Partial<Address> | null>(null)
  const [loaded] = useScript(
    "https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js"
  )
  const [_, setIsReady] = React.useState(false)

  useEffect(() => {
    if (loaded) {
      let control = new window.pca.Address(
        [{ element: "search", field: "", mode: window.pca.fieldMode.DEFAULT }],
        {
          key: "MM49-DD25-FR41-WY59",
          bar: { showCountry: false, showLogo: true, logoLink: false },
        }
      )
      control.listen("populate", (address: Address) => {
        setAddress(address)
      })

      let validateControl = new window.pca.Address(
        [
          {
            element: "validate",
            field: "",
            mode: window.pca.fieldMode.DEFAULT,
          },
        ],
        {
          key: "MM49-DD25-FR41-WY59",
          bar: { showCountry: false, showLogo: true, logoLink: false },
        }
      )
      validateControl.listen("populate", (address: Address) => {
        setvalidationAddress(address)
      })

      setIsReady(true)

      return () => {
        control.destroy()
        validateControl.destroy()
      }
    }
  }, [loaded])

  exclusion.current = pickBy(
    address,
    (_, k) => selectedFields[k as keyof Address]
  )

  return (
    <div className="max-w-2xl mx-auto mt-10">
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css?key=bw11-fu43-na37-by38"
        />
      </Helmet>
      <h2>Address Exclusion Tool</h2>
      <input
        placeholder="Address"
        type="text"
        className="w-full mb-4"
        name="search"
      />
      <div className="mx-auto mb-8 text-gray-500 whitespace-pre">
        {address?.Label}
      </div>
      <div className="grid grid-cols-2">
        <div
          style={{ gridTemplateColumns: "min-content max-content max-content" }}
          className="grid items-center gap-y-1 gap-x-3 auto-rows-min"
        >
          {address
            ? Object.entries(fields).map(([field, fieldName]) => (
                <>
                  <input
                    type="checkbox"
                    checked={selectedFields[field as keyof Address]}
                    onChange={(e) =>
                      setSelectedFields((selectedFields) => ({
                        ...selectedFields,
                        [field]: e.target.checked,
                      }))
                    }
                  />
                  <span className="font-light text-right">{fieldName}</span>
                  <span>{address[field as keyof Address]}</span>
                </>
              ))
            : "No address found."}
        </div>

        <div className="relative">
          <Copy
            onClick={() => {
              navigator.clipboard.writeText(
                JSON.stringify(exclusion.current, null, 2)
              )
            }}
            className="absolute top-0 right-0 h-4 text-gray-500 cursor-pointer hover:text-gray-200"
          />
          <pre className="font-mono font-light text-gray-800 select-all ">
            {JSON.stringify(exclusion.current, null, 2)}
          </pre>
        </div>
      </div>
      <h3 className="mt-12 mb-5">Testing</h3>

      {exclusion.current && (
        <>
          <div className="flex items-center gap-2">
            <input
              placeholder="Address"
              type="text"
              className="flex-1"
              name="validate"
            />

            {validationAddress &&
            testAddressIsNotExcluded(validationAddress, [exclusion.current]) ? (
              <CheckCircle className="w-6 h-6 text-green-600" />
            ) : (
              <TimesCircle className="w-6 h-6 text-red-600" />
            )}
          </div>
          <div className="mx-auto mb-8 text-gray-500 whitespace-pre">
            {validationAddress?.Label}
          </div>
        </>
      )}
    </div>
  )
}

export default AddressTool
