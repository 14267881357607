// Given an address and a list of exlusions, if all of the fields of any exclusion matches, then this address is invalid.
export function testAddressIsNotExcluded(
  address: Address,
  exclusionList: Array<Partial<Address>>
) {
  return !exclusionList.some((exclusion: Partial<Address>) =>
    Object.keys(exclusion).every(
      (key: string) =>
        exclusion[key as keyof Address] === address[key as keyof Address]
    )
  )
}

// export interface Address {
//   zip: string
//   state_code: string
//   country: string
//   id: string
//   domesticid: string
//   language: string
//   languagealternatives: string
//   department: string
//   company: string
//   apartment: string
//   buildingnumber: string
//   buildingname: string
//   secondarystreet: string
//   street: string
//   block: string
//   neighbourhood: string
//   district: string
//   city: string
//   line2: string
//   line1: string
//   line3: string
//   line4: string
//   line5: string
//   adminareaname: string
//   adminareacode: string
//   province: string
//   provincename: string
//   provincecode: string
//   // postalcode: string
//   countryname: string
//   countryiso2: string
//   countryiso3: string
//   countryisonumber: string
//   sortingnumber1: string
//   sortingnumber2: string
//   barcode: string
//   poboxnumber: string
//   label: string
//   type: string
//   datalevel: string
//   formattedline1: string
// }

// let potentiallyInteresting = {
//   SubBuilding: "",
//   BuildingNumber: "21",
//   BuildingName: "",
//   SecondaryStreet: "",
//   Street: "Bald Eagle Pl",
//   City: "Halifax",
//   Province: "NS",
//   ProvinceName: "Nova Scotia",
//   ProvinceCode: "NS",
//   PostalCode: "B3N 3H5",
//   POBoxNumber: "",
//   Type: "Residential",
//   DataLevel: "Premise",
// }

export interface Address {
  Id: string
  DomesticId: string
  Language: string
  LanguageAlternatives: string
  Department: string
  Company: string
  SubBuilding: string
  BuildingNumber: string
  BuildingName: string
  SecondaryStreet: string
  Street: string
  Block: string
  Neighbourhood: string
  District: string
  City: string
  Line1: string
  Line2: string
  Line3: string
  Line4: string
  Line5: string
  AdminAreaName: string
  AdminAreaCode: string
  Province: string
  ProvinceName: string
  ProvinceCode: string
  PostalCode: string
  CountryName: string
  CountryIso2: string
  CountryIso3: string
  CountryIsoNumber: string
  SortingNumber1: string
  SortingNumber2: string
  Barcode: string
  POBoxNumber: string
  Label: string
  Type: string
  DataLevel: string
  FormattedLine1: string
}

// let exampleAddress = {
//   Id: "CA|CP|B|8660218",
//   DomesticId: "8660218",
//   Language: "ENG",
//   LanguageAlternatives: "ENG,FRE",
//   Department: "",
//   Company: "",
//   SubBuilding: "",
//   BuildingNumber: "21",
//   BuildingName: "",
//   SecondaryStreet: "",
//   Street: "Bald Eagle Pl",
//   Block: "",
//   Neighbourhood: "",
//   District: "",
//   City: "Halifax",
//   Line1: "21 Bald Eagle Pl",
//   Line2: "",
//   Line3: "",
//   Line4: "",
//   Line5: "",
//   AdminAreaName: "",
//   AdminAreaCode: "",
//   Province: "NS",
//   ProvinceName: "Nova Scotia",
//   ProvinceCode: "NS",
//   PostalCode: "B3N 3H5",
//   CountryName: "Canada",
//   CountryIso2: "CA",
//   CountryIso3: "CAN",
//   CountryIsoNumber: 124,
//   SortingNumber1: "",
//   SortingNumber2: "",
//   Barcode: "",
//   POBoxNumber: "",
//   Label: "21 Bald Eagle Pl\nHALIFAX NS B3N 3H5\nCANADA",
//   Type: "Residential",
//   DataLevel: "Premise",
//   FormattedLine1: "21 Bald Eagle Pl",
// }
